import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import { Slide } from "react-slideshow-image";
import close from "../img/close.png";
import fullscreen from "../img/fullscreen.svg";
import thumbnails from "../img/thumbnails.svg";
import "react-slideshow-image/dist/styles.css";
import { Menu } from "../components/Menu/Menu";
import { Navbar } from "../components/Navbar";

const Wrapper = styled.div`
  padding: 150px 70px;
  height: 100%;
  display: flex;
  flex-direction: column;
  //position: relative;

  @media (max-width: 700px) {
    padding: 150px 30px;
  }
`;
const Slider = styled.div`
  //width: 100%;
  //padding-bottom: 56.25%;
  //position: relative;
`;

const SliderRatio = styled.div`
  //position: absolute;
  //top: 0;
  //bottom: 0;
  //left: 0;
  //right: 0;
`;

const Container = styled.div`
  z-index: 100000;
  ${({ isFullScreen }) => `
    position: ${isFullScreen ? "fixed" : "static"};
    width: ${isFullScreen ? "100vw" : "initial"};
    height: ${isFullScreen ? "100vh" : "initial"};
    top: ${isFullScreen ? 0 : "auto"};
    left: ${isFullScreen ? 0 : "auto"};
  `}

  .default-nav {
    background: transparent !important;
  }
  .default-nav:hover {
    background: transparent !important;
  }

  .default-nav:active {
    background: transparent !important;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;

const InfoView = styled.div`
  width: 100%;
  display: grid;

  @media (max-width: 1279px) {
    width: 100%;
    height: auto;
  }
`;

const DataInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: end;
`;

const Title = styled.h3`
  font-weight: bold;
  margin-top: 4px;
  width: 100%;
  color: black;
`;

const Line = styled.div`
  height: 4px;
  border-top: 4px solid black;
`;

const InfoViewWrapper = styled.div`
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr;
  margin: 120px auto 0;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: inherit;
    transition: background 0.9s ease-in-out;
    z-index: 200;

    &:hover {
      background: linear-gradient(
        90deg,
        rgba(29, 29, 27, 0.5018382352941176) 100%,
        rgba(29, 29, 27, 0.5102415966386555) 100%
      );
    }
  }

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: inherit;
  }
`;

const DataWrapper = styled.div`
  margin: 10px;
  color: black;
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: 250px;

  @media (max-width: 700px) {
    width: 50%;
  }
`;

const ProjectsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
  }
`;

const Value = styled.div`
  font-size: 18px;
  width: 100%;
  align-self: start;

  @media (max-width: 700px) {
    width: 50%;
  }
`;

const EachSlide = styled.div`
  > img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ isFullScreen }) => (isFullScreen ? "100vh" : "auto")};
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    @media (max-width: 1279px) {
      height: auto;
      width: 100%;
    }
  }
`;

const CloseIcon = styled.div`
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10000;
  background-size: 20px;
  height: 20px;
  width: 20px;
  background-image: url(${close});
`;

const FullScreenIcon = styled.div`
  padding: 0 0 20px 20px;
  cursor: pointer;
  background-size: 25px;
  height: 25px;
  width: 25px;
`;

const GridIcon = styled.div`
  padding: 0 0 20px 20px;
  cursor: pointer;
  background-size: 25px;
  height: 25px;
  width: 25px;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  right: 70px;
  z-index: 10010;

  @media (max-width: 700px) {
    right: 30px;
  }
`;

const TitleContainer = styled.div`
  @media (max-width: 1279px) {
    margin-bottom: 60px;
  }
`;

const DataItem = ({ label, value }) => {
  return (
    <DataWrapper>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </DataWrapper>
  );
};

const ProjectView = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        featuredImage,
        title_pl,
        title_eng,
        typology_pl,
        typology_eng,
        localization_pl,
        localization_eng,
        investor_pl,
        investor_eng,
        surface,
        year,
        team,
        galleryImages,
      },
    },
  } = data || {};

  const typologyLabel_pl = "typologia";
  const typologyLabel_eng = "typology";
  const localizationLabel_pl = "lokalizacja";
  const localizationLabel_eng = "location";
  const investorLabel_pl = "inwestor";
  const investorLabel_eng = "investor";
  const surfaceLabel_pl = "powierzchnia";
  const surfaceLabel_eng = "area";
  const yearLabel_pl = "rok";
  const yearLabel_eng = "year";
  const teamLabel_pl = "zespół";
  const teamLabel_eng = "team";
  const texts = {
    title_pl,
    title_eng,
    typologyLabel_pl,
    typologyLabel_eng,
    typology_pl,
    typology_eng,
    localizationLabel_pl,
    localizationLabel_eng,
    localization_pl,
    localization_eng,
    investorLabel_pl,
    investorLabel_eng,
    investor_pl,
    investor_eng,
    surfaceLabel_pl,
    surfaceLabel_eng,
    yearLabel_pl,
    yearLabel_eng,
    teamLabel_pl,
    teamLabel_eng,
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isGrid, setIsGrid] = useState(false);
  const toggleFullScreen = () => {
    if (isGrid) {
      setIsGrid(false);
    }
    setIsFullScreen(!isFullScreen);
  };
  const toggleGridScreen = () => {
    setIsGrid(!isGrid);
  };

  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
  }, []);

  const translate = (key) => {
    return `${key}_${language}`;
  };

  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
      localStorage.setItem("language", "eng");
    } else {
      setLanguage("pl");
      localStorage.setItem("language", "pl");
    }
  };
  return (
    <Layout>
      <>
        <Menu
          language={language === "pl" ? "eng" : "pl"}
          handleLanguageClick={handleLanguageClick}
        />
        <Navbar />
        <Wrapper>
          <Icons>
            <GridIcon onClick={toggleGridScreen}>
              <img src={thumbnails} />
            </GridIcon>
            <FullScreenIcon onClick={toggleFullScreen}>
              <img src={fullscreen} />
            </FullScreenIcon>
          </Icons>
          {!isGrid ? (
            <Slider>
              <SliderRatio>
                <Container isFullScreen={isFullScreen}>
                  {isFullScreen && (
                    <CloseIcon onClick={() => setIsFullScreen(false)} />
                  )}
                  <Slide
                    defaultIndex={currentIndex}
                    autoplay={false}
                    easing="ease"
                  >
                    {galleryImages.map((item) => (
                      <EachSlide isFullScreen={isFullScreen}>
                        <img
                          src={
                            !!item?.childImageSharp
                              ? item?.childImageSharp.fluid.src
                              : item
                          }
                        />
                      </EachSlide>
                    ))}
                  </Slide>
                </Container>
              </SliderRatio>
            </Slider>
          ) : (
            <ProjectsWrapper>
              {galleryImages.map((item, index) => (
                <GridItem
                  onClick={() => {
                    setCurrentIndex(index);
                    setIsFullScreen(true);
                    setIsGrid(false);
                  }}
                >
                  <div className="overlay" />
                  <img
                    src={
                      !!item?.childImageSharp
                        ? item?.childImageSharp.fluid.src
                        : item
                    }
                  />
                </GridItem>
              ))}
            </ProjectsWrapper>
          )}
          <InfoViewWrapper>
            <InfoView>
              <TitleContainer>
                <Line />
                <Title>{texts[translate("title")]}</Title>
              </TitleContainer>
              <DataInfoWrapper>
                <DataItem
                  label={texts[translate("typologyLabel")]}
                  value={texts[translate("typology")]}
                />
                <DataItem
                  label={texts[translate("localizationLabel")]}
                  value={texts[translate("localization")]}
                />
                <DataItem
                  label={texts[translate("investorLabel")]}
                  value={texts[translate("investor")]}
                />
                <DataItem
                  label={texts[translate("surfaceLabel")]}
                  value={surface}
                />
                <DataItem label={texts[translate("yearLabel")]} value={year} />
                <DataItem label={texts[translate("teamLabel")]} value={team} />
              </DataInfoWrapper>
            </InfoView>
            <ImageWrapper>
              <img
                src={
                  !!featuredImage?.childImageSharp
                    ? featuredImage?.childImageSharp.fluid.src
                    : featuredImage
                }
              />
            </ImageWrapper>
          </InfoViewWrapper>
        </Wrapper>
      </>
    </Layout>
  );
};

export default ProjectView;

export const pageQuery = graphql`
  query ProjectViewByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title_pl
        title_eng
        typology_pl
        typology_eng
        localization_pl
        localization_eng
        investor_pl
        investor_eng
        surface
        year
        team
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        galleryImages {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
